import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入elementui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import Fetch from './utils/fetch';
// Vue.prototype.Fetch = Fetch;
// 引入post和get方法
import { axiosPost, axiosGet, axiosDelete, axiosPut } from './axios/axios'
Vue.prototype.$axiosPost = axiosPost
Vue.prototype.$axiosGet = axiosGet
Vue.prototype.$axiosDelete = axiosDelete
Vue.prototype.$axiosPut = axiosPut

Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
